import { Component, OnInit } from '@angular/core';
import { AlertService } from '../../../../../shared/template-services/alert.service';
import { Applicant } from '../../../../interfaces/applicant';
import { ApplicantService } from '../../../../../shared/services/applicant.service';

@Component({
  selector: 'app-copy-applicant-docs-url',
  templateUrl: './copy-applicant-docs-url.component.html',
  styleUrls: ['./copy-applicant-docs-url.component.css']
})
export class CopyApplicantDocsUrlComponent implements OnInit {
  applicant: Applicant;
  applicantUrl: string = '';

  constructor(private _applicant: ApplicantService) {
  }

  async ngOnInit(): Promise<void> {
    this.applicantUrl = `https://postular.colsan.cl/aplicante/documentos/${this.applicant.key}`;
  }

  copyCode() {
    navigator.clipboard.writeText(this.applicant?.code || '');
    AlertService.toastSuccess('Se ha copiado el código');
  }

  copyLink() {
    navigator.clipboard.writeText(this.applicantUrl);

    AlertService.toastSuccess('Se ha copiado el enlace');
  }

}
