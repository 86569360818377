export enum PermissionsEnum {
  PEOPLE_MANAGEMENT = 'GESTIÓN DE PERSONAS',
  FLEET = 'FLOTA',
  FUNDS_TO_RENDER = 'FONDOS POR RENDIR',
  DEVIATIONS = 'DESVIACIONES',
  APPLICANTS = 'POSTULANTES',
  BLOG = 'BLOG',
  PROJECTS = 'PROYECTOS',
  SALES = 'VENTAS',
  TASKS = 'TAREAS',
  DOCUMENTS = 'DOCUMENTOS',
  TRAINING = 'CAPACITACIÓN',
  USERS = 'USUARIOS',
  WAREHOUSES = 'BODEGAS',
  TREASURY = 'TESORERÍA',
  FOUNDS_TO_RENDER = 'FONDOS POR RENDIR V2',
  PURCHASES = 'COMPRAS',
}
