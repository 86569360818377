import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { NavigationStart, Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { Location } from '@angular/common';
import { Sections } from '../constants/Sections';
import { mapApplicantPathToFilter, mapRecruitmentPathToFilter } from '../mappers/applicants.map';

@Injectable({
  providedIn: 'root'
})
export class EmbeddedLayoutService {
  uid$ = new BehaviorSubject<string>('');
  validationToken$ = new BehaviorSubject<string>('');
  hidden$ = new BehaviorSubject<boolean>(true);
  section$ = new BehaviorSubject<string>('');
  embeddedUrl$ = new BehaviorSubject<string>('');

  private prevUrlSection = '';
  private section: string;
  private uid: string;
  private validationToken: string;
  private url = window.location.pathname;

  constructor(
    private router: Router,
    private location: Location
  ) {
    this.checkInitialUrl(this.router.url);

    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.url = event.url;
        this.checkInitialUrl(event.url);
      }
    });
  }

  private getUrlParams(url: string) {
    if (url.includes('/applicant/')) {
      const applicantId = url.split('/').pop();

      return btoa(JSON.stringify({ path: `people-management/applicant/${applicantId}` }));
    } else {
      let paramType = 'filter';
      const isTab = url.includes('tab-');

      const subRoute = url.split('/').slice(2);
      let paramData: any = subRoute[2]?.includes('tab-')
        ? subRoute[subRoute.length - 1].split('-')[1]
        : subRoute[subRoute.length - 1];

      if (isTab) {
        paramData = btoa(JSON.stringify({ [paramType]: { tab: paramData } }));
      } else {
        let filterData = null;
        let type = null;

        if (url.includes('recruitment')) {
          filterData = mapRecruitmentPathToFilter[paramData];
          type = 'recruitment';
        } else {
          filterData = mapApplicantPathToFilter[paramData];
          type = 'status';
        }

        paramData = btoa(JSON.stringify({ [paramType]: [{ ...filterData, type }] }));
      }

      return btoa(JSON.stringify({
        path: subRoute.slice(0, 2).join('/'),
        params: subRoute.length > 2 ? { [paramType]: paramData } : {}
      }));
    }
  }

  private getExtraData(section: string, url: string) {
    if (!section || !url) return '';

    let dataParam = url.includes('data=') ? url.split('data=')[1] : '';

    if (section == 'people-management') dataParam = this.getUrlParams(url);
    dataParam = dataParam ? `&data=${dataParam}` : '';

    return dataParam;
  }

  private handleUrlUpdate(uid: string, token: string, section: string, url: string) {
    if (!uid || !token) return environment.embeddedUrl;
    let embeddedUrl = `${environment.embeddedUrl}/#/`;

    const saleIdMatch = url.match(/\/sales-v2\/inline-modal\/sale\/(.+)$/);
    const inductionIdMatch = url.match(/\/aplicante\/induccion\/(.+)$/);

    if (saleIdMatch) {
      const searchParams = new URLSearchParams(window.location.href.split('?')[1]);
      const boardId = searchParams.get('board');
      const params = boardId ? `?board=${boardId}` : '';

      const saleId = saleIdMatch[1];
      embeddedUrl += `sales/inline-modal/sale/${saleId}${params}`;
    } else if (inductionIdMatch) {
      const applicantId = inductionIdMatch[1];
      embeddedUrl += `training/inductions/${applicantId}`;
    } else {
      const dataParam = this.getExtraData(section, url);
      embeddedUrl += `${section}?uid=${uid}&token=${token}${dataParam}`;
    }

    this.embeddedUrl$.next(embeddedUrl);
  }

  isEmbeddedUrl(url: string) {
    const validRoutes = [
      '/vehicles',
      '/fund-to-render',
      '/people-management',
      '/blog',
      '/projects',
      '/sales-v2',
      '/induccion',
      '/deviations',
      '/boards-v2',
      '/requisitions',
      '/providerArticles'
    ];

    return validRoutes.some(route => url.includes(route));
  }

  getSection = (url: string) => {
    if (!url) return '';

    const mapEmbeddedUrlToSection = [
      { appUrl: Sections.app.vehicles, embeddedSection: Sections.embedded.vehicles },
      { appUrl: Sections.app.fundToRender, embeddedSection: Sections.embedded.fundToRender },
      { appUrl: Sections.app.peopleManagement, embeddedSection: Sections.embedded.peopleManagement },
      { appUrl: Sections.app.blog, embeddedSection: Sections.embedded.blog },
      { appUrl: Sections.app.projects, embeddedSection: Sections.embedded.projects },
      { appUrl: Sections.app.salesV2, embeddedSection: Sections.embedded.salesV2 },
      { appUrl: Sections.app.boardsV2, embeddedSection: Sections.embedded.boardsV2 },
      { appUrl: Sections.app.induction, embeddedSection: Sections.embedded.induction },
      { appUrl: Sections.app.deviations, embeddedSection: Sections.embedded.deviations },
      { appUrl: Sections.app.requisitions, embeddedSection: Sections.embedded.requisitions },
      { appUrl: Sections.app.providerArticles, embeddedSection: Sections.embedded.providerArticles }
    ];

    for (const { appUrl, embeddedSection } of mapEmbeddedUrlToSection) {
      if (url.includes(appUrl)) {
        return embeddedSection;
      }
    }
  };

  private checkInitialUrl(url: string) {
    this.section = this.getSection(url);
    this.section$.next(this.section);
    this.handleUrlUpdate(this.uid, this.validationToken, this.section, url);

    if (this.isEmbeddedUrl(url)) {
      this.hidden$.next(false);
    } else {
      this.hidden$.next(true);
    }
  }

  updateLayoutData(data: {
    uid?: string;
    validationToken?: string;
  }) {
    if (data.uid !== undefined) {
      this.uid$.next(data.uid);
      this.uid = data.uid;
    }
    if (data.validationToken !== undefined) {
      this.validationToken$.next(data.validationToken);
      this.validationToken = data.validationToken;
    }

    this.handleUrlUpdate(this.uid, this.validationToken, this.section, this.url);
  }

  getMessageData = (event: MessageEvent) => {
    if (event.origin !== environment.embeddedUrl) return;
    let data = event.data;

    if (this.section == 'people-management') {
      if (data) {
        this.location.replaceState('admin/' + this.section + data);
      } else {
        if (this.prevUrlSection) this.location.replaceState(this.section + this.prevUrlSection);
      }
    } else {
      if (data) data = JSON.parse(atob(data));

      if (data) {
        if (data.parentPath) {
          const queryParams = { data: event.data };
          this.router.navigate(['/admin/' + data.parentPath], { queryParams });
        } else {
          this.location.replaceState(event.data ? `${this.section}?data=${event.data}` : this.section);
        }
      } else {
      }
    }
  };
}
