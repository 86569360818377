import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '../shared/services/user.service';
import { MapPermissionToSectionPath } from '../shared/mappers/permissions.map';

@Injectable({
  providedIn: 'root'
})
export class PermissionGuard implements CanActivate {
  constructor(
    private _user: UserService,
    private router: Router
  ) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const permissions = this._user.user.permissions.map(permission => permission.section);
    const validPaths = permissions.map(permission => MapPermissionToSectionPath[permission]).flat();
    const isAuthorized = validPaths.some(path => state.url.includes(path));

    if (isAuthorized) return true;

    return this.router.navigate(['/admin/blog']);

  }
}
