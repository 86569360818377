import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UserService } from '../../services/user.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { User } from '../../interfaces/user';
import { LogsService } from '../../../admin/services/logs.service';
import { AngularFireStorage } from '@angular/fire/storage';
import { getIcon } from './types';
import { take } from 'rxjs/operators';
import { BsModalService } from 'ngx-bootstrap/modal';
import { TiUrlViewerComponent } from '../ti-url-viewer/ti-url-viewer.component';
import { ApplicantService } from '../../services/applicant.service';
import { AlertService } from '../../template-services/alert.service';

@Component({
  selector: 'ti-file',
  templateUrl: './ti-file.component.html',
  styleUrls: ['./ti-file.component.css']
})
export class TiFileComponent implements OnInit, OnChanges {
  @Input() applicant: any;
  @Input() user: User;
  @Input() fileUrl: string;
  @Input() label: string;
  @Input() name: string;
  @Input() accept: string = 'application/pdf';

  @Output() onFileChange: EventEmitter<any> = new EventEmitter<any>();

  storagePath: string;
  fileContentType: string;
  fileIcon: string;
  documents: Record<string, string>;

  constructor(
    private _user: UserService,
    private _applicant: ApplicantService,
    private SpinnerService: NgxSpinnerService,
    private _log: LogsService,
    private storage: AngularFireStorage,
    private modal: BsModalService
  ) {
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.fileUrl && changes.fileUrl.currentValue) {
      if (this.user) {
        this.storagePath = `user/${this.name}/${this.user.key}/${this.name.toUpperCase()}-${this.user.name}`;
      } else {
        this.storagePath = `applicants/${this.name}/${this.applicant.key}/${this.name.toUpperCase()}-${this.applicant.name}`;
      }

      if (this.fileUrl) this.loadMetadata();
    }
  }

  loadMetadata() {
    this.storage.ref(this.storagePath).getMetadata().pipe(take(1)).subscribe(metadata => {
      this.fileContentType = metadata.contentType;

      this.fileIcon = getIcon(this.fileContentType);
    }, error => {
      if (error.code == 'storage/object-not-found') {
        fetch(this.fileUrl).then(response => {
          this.fileContentType = response.headers.get('Content-Type');

          this.fileIcon = getIcon(this.fileContentType);
        });
      }
    });
  }

  async updateUser(file: File) {
    this.SpinnerService.show();
    const path = `users/${this.user.key}/${this.name.toUpperCase()}-${this.user.name}`;

    this.fileUrl = await this._applicant.uploadDocument(path, file);

    this._user.update(this.user.key, {
      [this.name]: this.fileUrl
    } as any);

    this._log.addApplicant(this.applicant.key, `Se actualizó el documento de ${this.label}`);
    this.SpinnerService.hide();
    AlertService.toastSuccess('Documento actualizado correctamente');
  }

  async chooseFile($event) {
    if (this.user) {
      this.updateUser($event.target.files[0]);
    } else {
      this.onFileChange.emit({ file: $event.target.files[0], type: this.name });
    }

    this.loadMetadata();
  }

  cannotView() {
    return this.fileIcon.includes('doc') || this.fileIcon.includes('xls') || this.fileIcon.includes('txt') || this.fileIcon.includes('file');
  }

  openViewer() {
    if (this.cannotView()) return this.downloadFile();

    this.modal.show(TiUrlViewerComponent, {
      initialState: {
        url: this.fileUrl,
        fileType: this.fileContentType,
        fileIcon: this.fileIcon
      },
      class: 'modal-xl'
    });
  }

  downloadFile() {
    this.SpinnerService.show();

    fetch(this.fileUrl).then(response => response.blob()).then(blob => {
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement('a');


      anchor.download = this.user
        ? `${(this.user.name || '').toUpperCase()}_${(this.user.surnames || '').toUpperCase()}_${this.label.toUpperCase()}`
        : `${(this.applicant.name || '').toUpperCase()}_${(this.applicant.surnames || '').toUpperCase()}_${this.label.toUpperCase()}`;
      anchor.href = url;
      anchor.click();

      this.SpinnerService.hide();
    });
  }
}
