<div
  class="vh-100 vw-100 d-flex justify-content-center align-items-center mb-4 flex-column bg-white"
  *ngIf="!authenticated && applicant"
>
  <div>
    <img
      src="../../../../assets/brand/logos/png/logo_color.png"
      style="width: 300px"
      alt="unlock-user"
      class="img-fluid center-block d-flex justify-content-center mb-3"
    >

    <p class="card-subtitle line-on-side text-muted text-center font-small-3 mb-3">
      <span>Acceder para subir documentos</span>
    </p>

    <fieldset class="form-group position-relative has-icon-left">
      <input
        type="text" class="form-control form-control-lg input-lg"
        autocomplete="disabled"
        [(ngModel)]="code"
        placeholder="Código proporcionado"
        [disabled]="!isValid"
      >
      <div class="form-control-position">
        <i class="la la-key"></i>
      </div>
    </fieldset>

    <button
      *ngIf="isValid"
      class="btn btn-outline-primary btn-lg btn-block mb-1"
      (click)="submitAuth()"
    >Entrar
    </button>
  </div>
</div>

<div class="row p-4" *ngIf="authenticated && applicant">
  <div class="col-12">
    <m-card>
      <ng-container mCardHeaderTitle>
        <div class="w-100 d-flex">
          <div class="mt-2 col-12 d-flex justify-content-center flex-column align-items-center ">
            <img [src]="bannerUrl" style=" height: 150px; max-width: 800px; margin-bottom: 20px; object-fit: contain"/>
            <div style="width: 80%">
              <p style="font-size: 20px; text-align: center">Estimado postulante, cada vez está más cerca de obtener el
                puesto deseado, y es por ello que lo
                invitamos
                a completar toda la información solicitada, ya que de lo contrario no podrá continuar en el proceso.
              </p>
              <p style="font-size: 20px; text-align: center">Esta documentación sólo será utilizada en el proceso de
                reclutamiento y no será divulgada en ningún
                momento.</p>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container mCardBody>
        <div class="card-block">
          <aw-wizard #wizard navBarLayout="large-filled-symbols">
            <aw-wizard-step [stepTitle]="'Datos generales'" [navigationSymbol]="{ symbol: '1'}">
              <app-general-data
                [applicant]="applicant"
                (eventChangeGeneralDataForm)="getGeneralDataForm($event)"
                (eventChangeDriverLicense)="getDriverLicense($event)"
              ></app-general-data>

              <div class="form-group text-center">
                <button *ngIf="generalData" type="button" class="btn btn-info btn-raised" awNextStep>
                  Siguiente
                  <span style="margin-left:10px;"><i class="feather ft-chevron-right"></i></span>
                </button>
                <button *ngIf="!generalData" type="button" class="btn btn-light btn-raised">
                  Siguiente
                  <span style="margin-left:10px;"><i class="feather ft-chevron-right"></i></span>
                </button>
              </div>
            </aw-wizard-step>

            <aw-wizard-step [stepTitle]="'Subir archivos'" [navigationSymbol]="{ symbol: '2'}">
              <app-documents
                [applicant]="applicant"
                [generalData]="generalData"
              ></app-documents>

              <div class="form-group text-center mt-2">
                <button type="button" class="btn btn-raised btn-danger mr-1" awPreviousStep>
                  <span style="margin-right:10px;">
                    <i class="feather ft-chevron-left"></i>
                  </span> Anterior
                </button>
                <button
                  (click)="submitGeneralData()" type="button" class="btn btn-success btn-raised"
                  awNextStep
                >
                  Finalizar
                  <span style="margin-left:10px;"><i class="feather ft-chevron-right"></i></span>
                </button>
              </div>
            </aw-wizard-step>
          </aw-wizard>
        </div>
      </ng-container>
    </m-card>
  </div>
</div>

<ngx-spinner
  bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff"
  type="square-jelly-box"
><p style="color: white"> {{ messageLoading }} </p></ngx-spinner>


