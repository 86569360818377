import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { take } from 'rxjs/operators';
import { ApplicantService } from '../../../shared/services/applicant.service';
import { AlertService } from '../../../shared/template-services/alert.service';
import { Applicant } from '../../../admin/interfaces/applicant';
import moment from 'moment';
import _ from 'lodash';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-register-data',
  templateUrl: './register-data.component.html',
  styleUrls: ['./register-data.component.css']
})
export class RegisterDataComponent implements OnInit, OnDestroy {
  complete: boolean = false;
  userKey: string;
  generalData: any;
  driverLicense: any;
  messageLoading: string = 'Guardando...';
  authenticated: boolean = false;
  code: string;
  applicant: Applicant;
  bannerUrl: string;
  isValid: boolean = false;
  subscription: Subscription;

  constructor(
    private _applicant: ApplicantService,
    private SpinnerService: NgxSpinnerService,
    private route: ActivatedRoute
  ) {
  }

  async ngOnInit(): Promise<any> {
    this.route.paramMap.subscribe(params => {
      const applicantId = params.get('id');
      if (this.subscription) this.subscription.unsubscribe();

      this.subscription = this._applicant.get(applicantId).subscribe((applicant) => {
        if (!applicant) AlertService.error('No se encontró su usuario, por favor contacte a su administrador');
        this.getApplicantData(applicant);
      });
    });
  }

  async getApplicantData(applicant: any) {
    this.isValid = true;

    if (!applicant.code) {
      this.isValid = false;
      AlertService.error('Aún no se ha generado un código para poder acceder, por favor contacte a su administrador');
    }

    const isExpired = moment().isAfter(applicant?.codeExpiration || 0);
    if (isExpired) {
      this.isValid = false;
      const expiration = moment(applicant.codeExpiration).format('DD/MM/YYYY [a las] HH:mm');
      AlertService.error(`El código expiró el ${expiration}, por favor contacte a su administrador`);
    }


    const settings = await this._applicant.getBannerUrl().pipe(take(1)).toPromise();
    this.bannerUrl = settings.selectionProcessBanner || './assets/images/process.png';
    this.applicant = applicant;
  }

  getGeneralDataForm(generalData: any) {
    this.generalData = generalData;
  }


  getDriverLicense(driverLicense: any) {
    this.driverLicense = driverLicense;
  }

  async submitGeneralData() {
    const expiration = moment(this.applicant.codeExpiration).format('DD/MM/YYYY HH:mm');
    const emptyDocuments = _.every(this.applicant.documents, _.isNull);

    if (
      emptyDocuments &&
      !await AlertService.confirm(
        'No se subieron documentos',
        `¿Estás seguro de continuar sin documentos? Tienes hasta el ${expiration} para subirlos`
      )
    ) {
      return;
    }

    this.SpinnerService.show();

    await this._applicant.update(this.applicant.key, { generalData: this.generalData });
    this.SpinnerService.hide();

    await AlertService.success('Datos guardados correctamente', 'Ya puedes cerrar esta ventana');
  }

  submitAuth() {
    if (this.code.trim().toLowerCase() != this.applicant.code?.trim()?.toLowerCase()) {
      return AlertService.error('Código o correo inválido');
    }

    this.authenticated = true;
    AlertService.toastSuccess('Código validado correctamente');
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
