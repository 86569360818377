export const PermissionLabel = {
  0: 'Usuario',
  1: 'Bodeguero',
  2: 'Supervisor',
  3: 'Administrador',
  4: 'Comprador',
  5: 'Finanzas',
  6: 'Prevencionista',
  7: 'Observador',
};
