import { Injectable } from '@angular/core';
import { FirebaseDataService } from '../template-services/firebase-data.service';
import { PurchaseOrder } from '../../admin/pages/purchase-orders/interfaces/purchase-order';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class RequisitionService {

  constructor(
    private db: FirebaseDataService,
    private _user: UserService
  ) {
  }

  getAllInProcess() {
    return this.db.colWithIds$<PurchaseOrder>('purchaseRequisitions', (ref) =>
      ref
        .where('trash', '==', false)
        .where('status', '==', 'inProgress')
        .orderBy('requisitionId', 'desc')
    );
  }

  async update(key: string, requisition: any): Promise<void> {
    return this.db.update(`purchaseRequisitions/${key}`, {
      ...requisition,
      updatedAt: Date.now(),
      updatedBy: this.db.getReference(`users/${this._user.user.key}`)
    });
  }

  getReference(key: string) {
    return this.db.getReference(`purchaseRequisitions/${key}`);
  }
}
