import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {ApplicantComponent} from "./applicant.component";
import {RegisterComponent} from "./pages/register/register.component";
import {UpdateCvComponent} from './pages/update-cv/update-cv.component';
import { RegisterDataComponent } from '../first-login/pages/register-data/register-data.component';
import { PreselectComponent } from './pages/preselect/preselect.component';
import { EmbeddedLayoutComponent } from '../layout/embedded-layout/embedded-layout.component';

const routes: Routes = [{
  path: '',
  component: ApplicantComponent,
  children: [
    {
      path: 'registro',
      component: RegisterComponent
    },
    {
      path: 'updateCv/:applicantKey',
      component: UpdateCvComponent
    },
    {
      path: 'documentos/:id',
      component: RegisterDataComponent
    },
    {
      path: 'preseleccion/:id/:status',
      component: PreselectComponent
    },
    {
      path: 'induccion/:id',
      component: EmbeddedLayoutComponent
    },
    {
      path: '**',
      redirectTo: 'registro'
    }
  ]
},];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ApplicantRoutingModule {
}
