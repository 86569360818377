import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Applicant } from '../../../admin/interfaces/applicant';
import { ApplicantService } from '../../../shared/services/applicant.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.css']
})
export class DocumentsComponent implements OnInit, OnChanges {
  @Input() applicant: Applicant;
  @Input() generalData: any;

  @Output() eventDocuments: EventEmitter<any> = new EventEmitter<any>();

  documentsForm: FormGroup;
  defaultDocuments: Record<string, string>;
  subscription: Subscription;

  constructor(
    private formBuilder: FormBuilder,
    private _applicant: ApplicantService
  ) {
    this.documentsForm = formBuilder.group({
      indentificationCardFront: ['', Validators.required],
      indentificationCardBack: ['', Validators.required],
      backgroundPaper: ['', Validators.required],
      birthCertificate: ['', Validators.required],
      afpAffiliationCertificate: [''],
      healthAffiliationCertificate: [''],
      driverLicensePhoto: [null],
      driverResume: [null],
      titlePhoto: [''],
      finiquitoUrl: [''],
      imageUrl: ['']
    });
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: { [property: string]: SimpleChange }) {
    this.generalData = changes['generalData'];

    if (changes.applicant && changes.applicant.currentValue) {
      this.documentsForm.patchValue(changes.applicant.currentValue);
      this.defaultDocuments = this.applicant.documents || {
        indentificationCardFront: null,
        indentificationCardBack: null,
        backgroundPaper: null,
        birthCertificate: null,
        afpAffiliationCertificate: null,
        healthAffiliationCertificate: null,
        driverLicensePhoto: null,
        driverResume: null,
        imageUrl: null,
        titlePhoto: null,
        cv: null,
        signature: null,
        finiquitoUrl: null,
        finiquito2Url: null,
        finiquito3Url: null
      };

      this.eventDocuments.emit(this.defaultDocuments);
    }
  }

  get formControls() {
    return this.documentsForm.controls;
  }

  async getFileUrl(file: File, name: string) {
    const path = `applicants/${this.applicant.key}/${name.toUpperCase()}-${this.applicant.name}`;
    return await this._applicant.uploadDocument(path, file)
  }

  async handleFileChange(data: any) {
    const newFileUrl = await this.getFileUrl(data.file, data.type);
    const newDocuments = { ...this.defaultDocuments, [data.type]: newFileUrl };

    await this._applicant.update(this.applicant.key, { documents: newDocuments });
    this.defaultDocuments = newDocuments;
    this.eventDocuments.emit(newDocuments);
  }
}
