import { Injectable } from '@angular/core';
import { User, User as UserInterface } from '../interfaces/user';
import { FirebaseDataService } from '../template-services/firebase-data.service';
import { AngularFirestore, DocumentReference } from '@angular/fire/firestore';
import { Observable, ReplaySubject } from 'rxjs';
import { UserType } from '../../admin/enums/user-type.enum';
import { debounceTime, first, map, take } from 'rxjs/operators';
import { AngularFireStorage } from '@angular/fire/storage';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  public user: User = null;
  $user: ReplaySubject<User> = new ReplaySubject<User>();
  id: string;
  users = [];

  constructor(
    private db: FirebaseDataService,
    private afs: AngularFirestore,
    private storage: AngularFireStorage,
    private http: HttpClient
  ) {
  }

  async addUserByKey(userKey: string, user: any): Promise<void> {
    if (!('trash' in user)) user['trash'] = false;

    await this.db.doc(`users/${userKey}`).set(user, { merge: true });
  }

  async getSpecificUser(userKey: string): Promise<User> {
    return await this.db
      .docWithId$<UserInterface>(`users/${userKey}`)
      .pipe(take(1))
      .toPromise();
  }

  getSpecificUser3(userKey: string): Observable<User> {
    return this.db.docWithId$<UserInterface>(`users/${userKey}`);
  }

  getSpecificUser2(userKey: string) {
    return this.afs.doc<UserInterface>(`users/${userKey}`).valueChanges();
  }

  getWithId$(userKey: string): Observable<User> {
    return this.db.docWithId$(`users/${userKey}`);
  }

  async get(userKey: string): Promise<User> {
    if (!this.user) return await this.loadUser(userKey);
    return this.user;
  }

  async loadUser(userKey: string): Promise<User> {
    return new Promise<User>((resolve) => {
      this.afs
        .doc<UserInterface>(`users/${userKey}`)
        .valueChanges()
        .subscribe((data) => {
          if (!data) resolve(null);

          this.user = data;
          this.user.key = userKey;
          this.$user.next(data);
          resolve(data);
        });
    });
  }

  getReference(userKey: string): DocumentReference {
    return this.afs.doc(`users/${userKey}`).ref;
  }

  getAll(): Observable<User[]> {
    return this.db.colWithIds$<User>('users');
  }

  getAllUndeleted(): Observable<User[]> {
    return this.db.colWithIds$<User>('users', (ref) =>
      ref.where('trash', '==', false)
    );
  }

  getAllApplicants(): Observable<User[]> {
    return this.db.colWithIds$<User>('users').pipe(
      map((users) => users.filter((user) => user.type != UserType.ADMIN)),
      map((users) => users.filter((user) => !!user.isApplicant)),
      map((users) => users.filter((user) => !user.trash))
    );
  }

  getAllOtherThanProject(project: DocumentReference): Observable<User[]> {
    return this.db.colWithIds$<User>('users').pipe(
      map((users) => users.filter((user) => user.type != UserType.ADMIN)),
      map((users) =>
        users.filter(
          (user: User) =>
            !user.projects ||
            !user.projects.find((projectItem) => projectItem.id == project.id)
        )
      )
    );
  }

  getAllByProject(projectReference): Observable<User[]> {
    return this.db
      .colWithIds$<User>('users', (ref) =>
        ref.where('projects', 'array-contains', projectReference)
      )
      .pipe(
        map((users) => users.filter((user) => user.type != UserType.ADMIN))
      );
  }

  getUsers(): Observable<User[]> {
    return this.db.colWithIds$<User>('users', (ref) =>
      ref.where('isDisable', '==', false).orderBy('name', 'desc')
    );
  }

  getAllByColsan(): Observable<User[]> {
    return this.db.colWithIds$<User>('users');
  }

  async update(userKey: string, user: Partial<User>) {
    await this.afs.doc(`users/${userKey}`).update(user);
  }

  delete(userKey: string) {
    this.afs.doc(`users/${userKey}`).update({ trash: true });
  }

  async uploadDocument(path, document) {
    const uploadRef = this.getStorageRefDocument(path);
    await uploadRef.put(document);
    const url = await uploadRef.getDownloadURL().pipe(take(1)).toPromise();
    this.uploadDocumentStorage(path, document);

    return url;
  }

  uploadDocumentStorage(path: string, data) {
    return this.storage.upload(path, data);
  }

  getStorageRefDocument(path: string) {
    return this.storage.ref(path);
  }

  resetPassword(uid: string, newPassword: string): Promise<any> {
    return this.http
      .post(`${environment.apiBaseURL}/resetPassword`, { uid, newPassword })
      .pipe(take(1))
      .toPromise();
  }

  createAccount(email: string, password: string) {
    return this.http
      .post(`${environment.apiBaseURL}/createAccount`, {
        email: email.trim(),
        password: password.trim()
      })
      .pipe(take(1))
      .toPromise();
  }

  async uploadPicture(photo, userKey) {
    const uploadRef = this.getStorageRef(userKey);
    await uploadRef.put(photo);
    const url = await uploadRef.getDownloadURL().pipe(take(1)).toPromise();
    this.uploadImage(photo, userKey);

    return url;
  }

  getStorageRef(id: string) {
    return this.storage.ref(`users/${id}/profile.jpeg`);
  }

  uploadImage(data, id) {
    return this.storage.upload(`users/${id}/profile.jpeg`, data);
  }

  getComments(userKey: any): Observable<any[]> {
    return this.db.colWithIds$(`users/${userKey}/comments`, (ref) =>
      ref.orderBy('date', 'desc')
    );
  }

  getPermission(sectionWanted: string) {
    const { permission } = this.user.permissions.find(({ section }) => section == sectionWanted) ?? {};
    return permission;
  }

  getPermissionBySection(sectionWanted: string) {
    const { permission } = this.user.permissions.find(({ section }) => section == sectionWanted) ?? {};
    return permission;
  }

  async getByEmail(email: string) {
    const resp = await this.db.colWithIds$('users', ref => ref
      .where('trash', '==', false)
      .where('email', '==', email)
    ).pipe(take(1)).toPromise();

    if (resp.length > 0) {
      return resp[0];
    }

    return null;
  }

  async getValidationToken() {
    const settings = await this.db.doc(`settings/setting`).valueChanges().pipe(take(1)).toPromise() as any;
    return settings.iframeToken;
  }

  async lookForRUTDuplicate(user: User) {
    if (!user.rut) return null;

    const users = await this.db.colWithIds$('users', ref => ref
      .where('trash', '==', false)
      .where('rut', '==', user.rut)
    ).pipe(debounceTime(500), first()).toPromise() as User[];

    console.log(users);
    if (users.length > 1) {
      return users.find(duplicatedUser => duplicatedUser.key !== user.key || duplicatedUser.rut !== user.rut);
    } else {
      return null;
    }
  }
}
