import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ApplicantService} from '../../../shared/services/applicant.service';
import {Applicant} from '../../../admin/interfaces/applicant';
import {NgxSpinnerService} from 'ngx-spinner';
import {ValidationService} from '../../../shared/template-services/validation.service';
import {AlertService} from '../../../shared/template-services/alert.service';
import moment from 'moment';

@Component({
  selector: 'app-induction',
  templateUrl: './induction.component.html',
  styleUrls: ['./induction.component.css']
})
export class InductionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}