import { PermissionsEnum } from '../enums/Permissions';

export const MapPermissionToSectionPath: Record<PermissionsEnum, string[]> = {
  [PermissionsEnum.PEOPLE_MANAGEMENT]: [
    '/admin/people-management'
  ],
  [PermissionsEnum.FLEET]: [
    '/admin/vehicles'
  ],
  [PermissionsEnum.FUNDS_TO_RENDER]: [
    '/admin/fund-to-render'
  ],
  [PermissionsEnum.APPLICANTS]: [
    '/admin/applicants'
  ],
  [PermissionsEnum.BLOG]: [
    '/admin/blog'
  ],
  [PermissionsEnum.PROJECTS]: [
    '/admin/projects'
  ],
  [PermissionsEnum.SALES]: [
    '/admin/sales-v2'
  ],
  [PermissionsEnum.TASKS]: [
    '/admin/boards-v2'
  ],
  [PermissionsEnum.DOCUMENTS]: [
    '/admin/documents'
  ],
  [PermissionsEnum.TRAINING]: [
    '/admin/training'
  ],
  [PermissionsEnum.USERS]: [
    '/admin/usuarios'
  ],
  [PermissionsEnum.WAREHOUSES]: [
    '/admin/warehouse',
    '/admin/categories',
  ],
  [PermissionsEnum.TREASURY]: [
    '/admin/bills',
    '/admin/proofPayments',
    '/admin/workerSalaries',
    '/admin/totalExpenses',
    '/admin/reports',
    '/admin/project-invoice-reports',
  ],
  [PermissionsEnum.FOUNDS_TO_RENDER]: [
    '/admin/funds-to-render-v2'
  ],
  [PermissionsEnum.PURCHASES]: [
    '/admin/purchase-orders',
    '/admin/providers',
    '/admin/providerArticles',
    '/admin/units',
    '/admin/requisitions',
    '/admin/id-settings'
  ],
  [PermissionsEnum.USERS]: [
    '/admin/users'
  ],
  [PermissionsEnum.DEVIATIONS]: [
    '/admin/deviations'
  ]
};
