export enum UserType {
  USER,
  GROCER,
  SUPERVISOR,
  ADMIN,
  BUYER,
  FINANCE,
  PREVENTIONS,
  OBSERVER
}
