import { Component, Input, OnInit } from '@angular/core';
import { User } from '../../../shared/interfaces/user';
import { Observable, of } from 'rxjs';
import { DataTableConfig } from '../../../shared/interfaces/data-table-config';
import { Sections } from '../../data/sections.data';
import { UserType } from '../../enums/user-type.enum';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService } from '../../../shared/template-services/alert.service';
import { UserService } from '../../../shared/services/user.service';
import { PermissionLabel } from '../../labels/permission.label';
import { LogsService } from '../../services/logs.service';
import { WarehouseService } from '../../../shared/services/warehouse.service';

@Component({
  selector: 'app-permissions',
  templateUrl: './permissions.component.html',
  styleUrls: ['./permissions.component.css']
})
export class PermissionsComponent implements OnInit {
  @Input() user: User;
  @Input() userKey: string;
  dataTableConfig: DataTableConfig = {
    hasSearch: false,
    notFoundText: 'El usuario no tiene permisos',
    title: ''
  };
  permissions$: Observable<any> = new Observable<any>();
  permissions: any[] = [
    {
      label: 'Usuario',
      permission: UserType.USER
    },
    {
      label: 'Supervisor',
      permission: UserType.SUPERVISOR
    },
    {
      label: 'Administrador',
      permission: UserType.ADMIN
    }
  ];

  fleetPermissions: any[] = [
    ...this.permissions,
    {
      label: 'Prevencionista',
      permission: UserType.PREVENTIONS
    }
  ];

  warehousesPermissions: any[] = [
    ...this.permissions,
    {
      label: 'Bodeguero',
      permission: UserType.GROCER
    },
    {
      label: 'Comprador',
      permission: UserType.BUYER
    }
  ];

  purchasesPermissions: any[] = [
    ...this.permissions,
    {
      label: 'Finanzas',
      permission: UserType.FINANCE
    }
  ];

  peopleManagementPermissions: any[] = [
    ...this.permissions,
    {
      label: 'Observador',
      permission: UserType.OBSERVER
    }
  ];

  sections: string[] = Sections;
  permissionForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private _user: UserService,
    private _log: LogsService,
    private _warehouse: WarehouseService
  ) {
    this.permissionForm = formBuilder.group({
      section: ['', Validators.required],
      permission: ['', Validators.required]
    });
  }

  ngOnInit(): void {
    this.updatePermissionsTable();
  }

  private updatePermissionsTable() {
    this.permissions$ = of(
      this.user.permissions.map((permission) => ({
        permission: {
          label: PermissionLabel[permission.permission],
          permission: permission.permission
        },
        section: permission.section
      }))
    );
  }

  async addPermission() {
    if (!!this.user.permissions.find((permission) => permission.section == this.permissionForm.get('section').value)) {
      AlertService.toastError('Esta sección ya tiene permisos');
      this.permissionForm.reset();
      return;
    }

    if (!this.permissionForm.valid) return AlertService.toastError('Falta agregar seleccionar la sección o el permiso');

    const permission = this.permissionForm.get('permission').value['permission'];

    this.user.permissions.push({
      permission,
      section: this.permissionForm.get('section').value
    });

    this.updatePermissionsTable();

    await this._user.update(this.userKey, this.user);

    this._log.addUser(
      this.user.key,
      `Se agregó el permiso ${this.permissionForm.get('section').value}`
    );

    this.permissionForm.reset();
  }

  async updatePermission(event: any, index) {
    this.user.permissions[index].permission = event.permission;
    this._user.update(this.userKey, this.user);
     this._log.addUser(
      this.user.key,
      `Se actualizó el permiso de ${event.label}`
    );
  }

  async deletePermission(index, row) {
    if (await AlertService.confirm('¿Estás seguro que deseas eliminar este permiso?')) {
      if (row.section == 'BODEGAS') {
        if (!await this._warehouse.revokePermissions(this.userKey)) return;
      }
      this.user.permissions.splice(index, 1);
      this.updatePermissionsTable();

      this._log.addUser(this.user.key, `Se eliminó el permiso ${row.section}`);
      await this._user.update(this.userKey, this.user);
    }
  }

  getPermissionList(section: string) {
    switch (section) {
      case 'BODEGAS':
        return this.warehousesPermissions;
      case 'COMPRAS':
        return this.purchasesPermissions;
      case 'FLOTA':
        return this.fleetPermissions;
      case 'GESTIÓN DE PERSONAS':
        return this.peopleManagementPermissions;
      default:
        return this.permissions;
    }
  }
}
