<div class="p-2" *ngIf="applicant">
  <form [formGroup]="generalForm">
    <div class="row mt-2">
      <div class="col-md-4 col-12">
        <ti-input
          label="Dirección"
          mode="material"
          [control]="formControls.address"
        ></ti-input>
      </div>

      <div class="col-md-4 col-12">
        <ti-select
          [control]="formControls.afp"
          label="AFP"
          bindLabel="name"
          [options]="selectArrayAfp"
        ></ti-select>
      </div>

      <div class="col-md-4 col-12">
        <ti-select
          [control]="formControls.healthcareSystem"
          label="Sistema de salud"
          [options]="medicServices"
        ></ti-select>
      </div>

      <div class="col-md-4 col-12" *ngIf="applicant?.educationLevel == 'Enseñanza Universitaria Completa'">
        <ti-input
          label="Título"
          mode="material"
          [control]="formControls.title"
        ></ti-input>
      </div>

      <div class="col-md-4 col-12">
        <ti-select
          [control]="formControls.accountType"
          label="Tipo de cuenta"
          [options]="accountTypeArray"
        ></ti-select>
      </div>

      <div class="col-md-4 col-12">
        <ti-select
          [control]="formControls.bank"
          label="Banco"
          [options]="bankArray"
        ></ti-select>
      </div>

      <div class="col-md-4 col-12">
        <ti-input
          label="Número de cuenta"
          mode="material"
          type="number"
          [control]="formControls.accountNumber"
        ></ti-input>
      </div>

      <div class="col-md-4 col-12">
        <ti-input
          label="Persona de emergencia"
          mode="material"
          [control]="formControls.emergencyPerson"
        ></ti-input>
      </div>

      <div class="col-md-4 col-12">
        <ti-input
          label="Número de emergencia"
          mode="material"
          type="number"
          [control]="formControls.emergencyNumber"
        ></ti-input>
      </div>

      <div class="col-md-4 col-12">
        <ti-select
          [control]="formControls.numberOfShoes"
          label="Número de zapatos"
          [options]="sizeShoesArray"
        ></ti-select>
      </div>

      <div class="col-md-4 col-12">
        <ti-select
          [control]="formControls.pilotDriverSize"
          label="Talla de Buzo Piloto"
          [options]="sizeArray"
        ></ti-select>
      </div>

      <div class="col-md-4 col-12">
        <ti-select
          [control]="formControls.shirtSize"
          label="Talla de Polera"
          [options]="sizeArray"
        ></ti-select>
      </div>

      <div class="col-md-4 col-12">
        <label>Lentes ópticos *</label>
        <div class="form-group" (change)="changeApplicantForm()">
          <div class="custom-control custom-radio custom-control-inline">
            <input type="radio" id="radioOpticalLenses1" class="custom-control-input"
                   [value]="'Aplica'"
                   [ngClass]="{ 'is-invalid': submitted && formControls.opticalLenses.errors }"
                   formControlName="opticalLenses">
            <label class="custom-control-label" for="radioOpticalLenses1">Si</label>
          </div>

          <div class="custom-control custom-radio custom-control-inline">
            <input type="radio" id="radioOpticalLenses2" class="custom-control-input"
                   [value]="'No aplica'"
                   [ngClass]="{ 'is-invalid': submitted && formControls.opticalLenses.errors }"
                   formControlName="opticalLenses">
            <label class="custom-control-label" for="radioOpticalLenses2">No</label>
          </div>
        </div>
        <div class="form-text text-muted danger invalid-feedback"
             *ngIf="submitted && formControls.opticalLenses.errors">
          Es obligatorio
        </div>
      </div>

      <div class="col-md-4 col-12" (change)="driverLicenseEmit()">
        <label>Licencia de conducir *</label>
        <div class="form-group" (change)="changeApplicantForm()">
          <div class="custom-control custom-radio custom-control-inline">
            <input type="radio" id="customRadioInline1" class="custom-control-input"
                   [value]="'Con licencia'"
                   [ngClass]="{ 'is-invalid': submitted && formControls.driverLicense.errors }"
                   formControlName="driverLicense">
            <label class="custom-control-label" for="customRadioInline1">Si</label>
          </div>

          <div class="custom-control custom-radio custom-control-inline">
            <input type="radio" id="customRadioInline2" class="custom-control-input"
                   [value]="'Sin licencia'"
                   [ngClass]="{ 'is-invalid': submitted && formControls.driverLicense.errors }"
                   formControlName="driverLicense">
            <label class="custom-control-label" for="customRadioInline2">No</label>
          </div>
        </div>
        <div class="form-text text-muted danger invalid-feedback"
             *ngIf="submitted && formControls.driverLicense.errors">
          Es obligatorio
        </div>
      </div>
    </div>
  </form>
</div>
