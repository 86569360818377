<ng-container *ngIf="!!purchaseOrder">
  <div class="d-flex justify-content-between ml-2 mr-2">
    <app-provider-info-view [providerInfo]="provider"></app-provider-info-view>

    <div
      class="col-md-4 col-12 text-center text-md-right"
      *ngIf="!!purchaseOrder.provider"
    >
      <h2 class="font-weight-bold">Orden de compra</h2>
      <h3 class="font-weight-bold">OC: {{purchaseOrder.purchaseID | slice:0 : -3}}
        <span class="text-muted font-size-xsmall">#{{purchaseOrder?.purchaseID | slice:-3 }}</span></h3>
      <h3>Fecha de creación: {{purchaseOrder.date | date : 'dd/MM/yyyy'}}</h3>
      <h3 *ngIf="!isPrint">Proyecto: {{(this.purchaseOrder.project | doc | async)?.name}}</h3>
      <h3>Proveedor: {{(purchaseOrder.provider | doc | async)?.name}}</h3>
      <h3>Categoría: {{(purchaseOrder.budget | doc | async)?.name}}</h3>
      <h3 *ngIf="purchaseOrder.requisition">Requisición: {{ (purchaseOrder.requisition | doc | async)?.requisitionId }}</h3>
      <h3>Descripción</h3>
      <p>{{description ? description : 'Sin descripción'}}</p>
      <div *ngIf="categoryIsExams">
        <button class="btn btn-info btn-sm mb-1" (click)="openDescriptionForm()">Modificar descripción</button>
      </div>
      <h3>Condiciones comerciales:</h3>
      <p>{{bussinessConditions ? bussinessConditions : 'Sin condiciones comerciales'}}</p>

      <div class="d-flex justify-content-end">
        <span
          class="badge badge-pill badge-success mr-1"
          [ngClass]="{
                  'badge-success': !!purchaseOrder.received,
                  'badge-danger': !purchaseOrder.received}"
          ngbTooltip="{{!!purchaseOrder.received ? 'Entregado' : 'No entregado'}}"
        >
            <i class="la la-truck"></i></span>

        <span
          class="badge badge-pill"
          [ngClass]="{
                  'badge-success': !!purchaseOrder.invoiced,
                  'badge-danger': !purchaseOrder.invoiced}"
          ngbTooltip="{{!!purchaseOrder.invoiced ? 'Facturado' : 'No facturado'}}"
        >
            <i class="la la-file-pdf-o"></i>
          </span>

      </div>
    </div>
  </div>

  <mat-tab-group>
    <mat-tab label="Artículos">
      <app-articles-purchase-view
        [articlesPurchaseEdit]="articlesPurchase"
        [date]="purchaseOrder.date"
        [deliveryReceiver]="purchaseOrder.deliveryDate"
        [approvalDateReceiver]="purchaseOrder.approvalDate"
        [ticketStatus]="purchaseOrder.status"
      ></app-articles-purchase-view>
      <div class="d-flex flex-column align-items-center justify-content-center ">
        <img
          src="{{purchaseOrder.signature}}"
          class="signature"
        >
        <div class="text-center">
          <p class="mb-0 font-weight-bold">________________________________________________________</p>
          <p class="mb-0 font-weight-bold">FIRMA AUTORIZADA
            POR: {{purchaseOrder.supervisor ? (purchaseOrder?.supervisor | doc | async)?.name : 'Sin asignar'}}</p>
        </div>
      </div>

      <div
        class="ml-2 word-break"
        *ngIf="this.purchaseOrder.status == purchaseStatus.CANCELLED"
      >
        <h3>Motivos de la cancelación:</h3>
        <label>{{!!purchaseOrder?.cancelMessage
          ? purchaseOrder?.cancelMessage
          : 'No se asignó motivo'}}</label>
      </div>
    </mat-tab>

    <mat-tab
      label="Cotización"
      *ngIf="quotation"
    >
      <div class="col-12 center-layout center">
        <ng-container *ngIf="permission == permissionEnum.SUPERVISOR || permission == permissionEnum.ADMIN">
          <app-upload-file-storage
            label="Actualizar cotización"
            extension="pdf"
            errorLabel="La cotización es obligatoria"
            controlName="quotation"
            (isImageLoaded)="quotationLoaded()"
            [showPreview]="false"
          ></app-upload-file-storage>
          <button
            class="btn btn-success float-right"
            (click)="updateQuotation()"
          ><i class="feather ft-refresh-ccw"></i>
          </button>
        </ng-container>
        <div class="card shadow-none">
          <div class="card-body pt-2">
            <div class="write-post">
              <div class="col-sm-12 px-2">
                <div
                  *ngIf="isPdf"
                  [innerHTML]="url"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-tab>

    <mat-tab label="Comentarios">
      <app-purchase-comments [purchaseOrder]="purchaseOrder"></app-purchase-comments>
    </mat-tab>

    <mat-tab label="Registros">
      <app-logs [logs$]="logs$"></app-logs>
    </mat-tab>

    <mat-tab label="Facturas">
      <app-purchase-order-bills
        [purchaseOrder]="purchaseOrder"
      ></app-purchase-order-bills>
    </mat-tab>
  </mat-tab-group>

  <div class="d-flex justify-content-end mt-2 mr-2 mb-2" id="purchase-buttons-node">

    <button
      *ngIf="permission == permissionEnum.ADMIN"
      class="btn btn-primary mr-2"
      (click)="changeStatusToDraft()"
    >Pasar a borrador
    </button>

    <button
      class="btn btn-primary mr-2"
      *ngIf="(!purchaseOrder.invoice || purchaseOrder.invoice.length == 0 || !(purchaseOrder?.invoice[0] | doc | async)?.billPdf) &&
      (permission == permissionEnum.ADMIN || permission == permissionEnum.SUPERVISOR)"
      (click)="cancelPurchase()"
    >Cancelar compra
    </button>

    <button
      class="btn btn-primary mr-2"
      *ngIf="this.purchaseOrder.status == purchaseStatus.AUTHORIZED && (permission == permissionEnum.ADMIN || permission == permissionEnum.SUPERVISOR)"
      (click)="setIncompleteOrder()"
    >Orden incompleta
    </button>

    <button
      class="btn btn-dark cursor-pointer mr-2"
      *ngIf="this.purchaseOrder.status == purchaseStatus.AUTHORIZED"
      (click)="addInvoice()"
    >Adjuntar factura
    </button>

    <button
      class="btn btn-success cursor-pointer mr-2"
      *ngIf="!this.purchaseOrder.received && this.purchaseOrder.status == purchaseStatus.AUTHORIZED"
      (click)="changeToReceived()"
    >Confirmar pedido
    </button>

    <button
      class="btn btn-info cursor-pointer mr-2"
      *ngIf="!this.purchaseOrder.invoiced && this.purchaseOrder.status == purchaseStatus.AUTHORIZED"
      (click)="sendPdf()"
    >Enviar PDF a proveedor
    </button>

    <button
      class="btn btn-warning cursor-pointer mr-2"
      (click)="print()"
    >Imprimir
    </button>

    <button
      class="btn btn-primary mr-2"
      (click)="close()"
    >Cerrar
    </button>
  </div>
</ng-container>

<ngx-spinner
  bdColor="rgba(0, 0, 0, 0.8)"
  size="medium"
  color="#fff"
  type="square-jelly-box"
><p style="color: white">{{ spinnerMessage }}</p></ngx-spinner>
